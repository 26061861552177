import {Component, OnInit} from '@angular/core';
import {ApsimService} from '../apsim/apsim.service';
import {SoilProfileEnums} from '../bar-graph/soil-profile.enums';
import * as $ from 'jquery';
import 'jqueryui';
import {SelectorModel} from './selector.model';

@Component({
  selector: 'app-selector',
  templateUrl: './selector.component.html',
  styleUrls: ['./selector.component.css']
})
export class SelectorComponent implements OnInit {

  /* Declaring constants that are used in selector component */
  coverCrops = ['Rye'];
  soilProfiles = [
    SoilProfileEnums.Crete_Silt_Loam_No3824,
    SoilProfileEnums.Sandy_loam_No200,
    SoilProfileEnums.Wabash_Silty_Clay_Loam,
    SoilProfileEnums.Thurman_Crofton_Sand
  ];

  /* Object to store all the important selected fields */
  selectedOptions: SelectorModel = {
    coverCrop: '',
    soilProfile: '',
    startDay: '',
    startMonth: '',
    terminationDay: '',
    terminationMonth: ''
  };

  constructor(private apsimService: ApsimService) {
  }

  ngOnInit(): void {
    const self = this;
    ($('#planting-date') as any).datepicker( {
      changeYear: false,
      dateFormat: 'mm/dd',
      beforeShowDay(date) {
        const month = date.getUTCMonth();
        return [(month === 7 || month === 8 || month === 9 || month === 10), '']; // Only enable the months August - November
      },
      onSelect(dateText, inst) {
        const dateSplit = dateText.split('/');
        const month = dateSplit[0];
        const date = dateSplit[1];
        self.selectedOptions.startMonth = month;
        self.selectedOptions.startDay = date;
        self.checkSelectedOptions();
      }
    }).focus(() => {
      $('.ui-datepicker-year').hide();
    });
    ($('#termination-date') as any).datepicker( {
      changeYear: false,
      dateFormat: 'mm/dd',
      beforeShowDay(date) {
        const month = date.getUTCMonth();
        return [(month === 2 || month === 3 || month === 4 || month === 5), '']; // Only enable the months March - June
      },
      onSelect(dateText, inst) {
        const dateSplit = dateText.split('/');
        const month = dateSplit[0];
        const date = dateSplit[1];
        self.selectedOptions.terminationMonth = month;
        self.selectedOptions.terminationDay = date;
        self.checkSelectedOptions();
      }}).focus(() => {
        $('.ui-datepicker-year').hide();
      });
    this.apsimService.compare.subscribe((compare: boolean) => {
      if (compare) {
        $('select').prop('disabled', 'disabled');
      } else {
        $('select').prop('disabled', false);
      }
    });

  }

  /**
   * Checks all the field inside Selected Option.
   * Emit Changes only when all the options(fields like soilProfile etc.) are selected
   */
  checkSelectedOptions() {
    if (this.selectedOptions.coverCrop !== '' &&
      this.selectedOptions.soilProfile !== '' && this.selectedOptions.startMonth !== '' &&
      this.selectedOptions.startDay !== '' && this.selectedOptions.terminationMonth !== '' &&
      this.selectedOptions.terminationDay !== '') {
      this.apsimService.selectedOptions.emit(this.selectedOptions);
    }
  }

}
