import {Component, OnInit} from '@angular/core';
import * as $ from 'jquery';

@Component({
  selector: 'app-economics',
  templateUrl: './economics.component.html',
  styleUrls: ['./economics.component.css']
})
export class EconomicsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    this.updateCell();
  }

  /**  This function get all the elements inside the spreadsheet that needs the
   *  values to be assigned dynamically. For any change occurred, the entire elements
   *  values are updated
   */
  public updateCell() {
    const calculatedCells = $('.calculatedCell');
    let totalCost1 = 0;
    let totalCost2 = 0;
    let totalSaving1 = 0;
    let totalSaving2 = 0;

    let totalCol1 = 0;
    let totalCol2 = 0;
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < calculatedCells.length; i++) {
      const previousCell = parseFloat(calculatedCells[i].previousElementSibling.innerHTML);
      const prePreviousCell = parseFloat(calculatedCells[i].previousElementSibling.previousElementSibling.innerHTML);

      calculatedCells[i].innerHTML = String(previousCell * prePreviousCell);

      if ((i + 1) % 2 !== 0) {
        totalCol1 += parseFloat(calculatedCells[i].innerHTML);
      } else {
        totalCol2 += parseFloat(calculatedCells[i].innerHTML);
      }

      if ((i + 1) % 8 === 0 && (i + 1) % 16 !== 0) {
        const calculatedTotal = $('.calculatedTotal');
        totalCost1 = totalCol1;
        totalCost2 = totalCol2;

        calculatedTotal[0].innerHTML = String(totalCost1);
        calculatedTotal[1].innerHTML = String(totalCost2);

        totalCol1 = 0;
        totalCol2 = 0;
      }

      if ((i + 1) % 16 === 0) {
        const calculatedTotal = $('.calculatedTotal');
        totalSaving1 = totalCol1;
        totalSaving2 = totalCol2;

        calculatedTotal[2].innerHTML = String(totalSaving1);
        calculatedTotal[3].innerHTML = String(totalSaving2);
        totalCol1 = 0;
        totalCol2 = 0;
      }

    }
    const netReturn = $('.calculatedNetReturn');
    netReturn[0].innerHTML = String(totalSaving1 - totalCost1);
    netReturn[1].innerHTML = String(totalSaving2 - totalCost2);
  }

}
