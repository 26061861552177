import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import {RouterModule, Routes} from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { ToastrModule } from 'ngx-toastr';

import { AppComponent } from './app.component';
import { SelectorComponent } from './home/selector/selector.component';
import { MapSelectorComponent } from './home/map-selector/map-selector.component';
import { BarGraphComponent } from './home/bar-graph/bar-graph.component';
import {FormsModule} from '@angular/forms';
import { EconomicsComponent } from './economics/economics.component';
import { FaqComponent } from './faq/faq.component';
import { HomeComponent } from './home/home.component';
import { ApsimComponent } from './home/apsim/apsim.component';
import { IntroComponent } from './intro/intro.component';
import { ReportComponent } from './report/report.component';
import { AboutComponent } from './about/about.component';
import {APP_BASE_HREF} from '@angular/common';

const appRoutes: Routes = [
  { path: '', component: HomeComponent},
  { path: 'economics', component: EconomicsComponent},
  { path: 'report', component: ReportComponent},
  { path: 'faqs', component: FaqComponent},
  { path: 'about', component: AboutComponent},
  { path: 'intro', component: IntroComponent}
];

@NgModule({
  declarations: [
    AppComponent,
    SelectorComponent,
    MapSelectorComponent,
    BarGraphComponent,
    EconomicsComponent,
    FaqComponent,
    HomeComponent,
    ApsimComponent,
    IntroComponent,
    AboutComponent,
    ReportComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    MatSlideToggleModule,
    RouterModule.forRoot(appRoutes, { useHash: true}),
    ToastrModule.forRoot()
  ],
  providers: [
    { provide: APP_BASE_HREF, useValue: window['_app_base'] || '/' },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
