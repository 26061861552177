import { Component, OnInit } from '@angular/core';
import {SimulationResultModel} from './simulationResult.model';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {NotificationService} from '../../notifications/notification.service';
import {StatusCodes} from 'http-status-codes/build/es';
import * as d3 from 'd3';
import {SimulationModel} from './simulation.model';
import {TypeConverter} from '../../common/utility/typeConverter.service';
import {ColumnEnums} from '../home/bar-graph/column.enums';
import {BarGraphComponent} from '../home/bar-graph/bar-graph.component';
import {YearlyValue} from '../../common/models/yearlyValue.model';
import {DaysToWeeksConverter} from '../../common/utility/daysToWeeksConverter.service';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {

  private simulationResultModels: SimulationResultModel[];

  /** URL to the Apsim backend Services */
  private URL = 'https://covercrops.unl.edu/api/';
  private apiServiceEndpoint = 'apsim/database/results/';
  private simulationModels: SimulationModel[] = [];
  private metFiles = [];
  private soilProfiles = [];
  private coverCrops = [];
  private startMonths: string[] = [];
  private terminationMonths: string[] = [];
  private biomassTicks: number[] = [];
  private transpirationTicks: number[] = [];
  private nUptakeTicks: number[] = [];
  private weeks: string[] = [];
  public loading;

  constructor(private http: HttpClient, private notifyService: NotificationService) {
    this.loading = true;

    this.metFiles = ['', 'Northwest Nebraska 43 103', 'North Central Nebraska 43 101', 'North Central Nebraska 43 99',
      'Northeast Nebraska 43 97', 'Southwest Nebraska 41 103', 'Southwest Nebraska 41 101', 'Central Nebraska 41 99',
      'Southeast Nebraska 41 97', 'Southeast Nebraska 41 95', ' '];

    this.soilProfiles = [
      '',
      'Crete Silt Loam No3824',
      'Sandy loam No200',
      'Wabash Silty Clay Loam',
      'Thurman Crofton Sand',
      ' '];

    this.coverCrops = ['', 'Rye', ' '];
    this.startMonths = ['', 'August', 'September', 'October', 'November', ' '];
    this.terminationMonths = ['', 'March', 'April', 'May', 'June', ' '];
    this.weeks = ['', 'First', 'Second', 'Third', 'Fourth', ' '];
  }

  ngOnInit() {
    this.fetchSimulationResultModelsAndGenerateReport();
  }

  /** Loads the values in the Biomass model */
  private loadSimulationModels(simulationResultModels: SimulationResultModel[]) {
    simulationResultModels.forEach(simulation => {
      const simulationModel = {} as SimulationModel;
      const biomassData: string[] = TypeConverter.convertStringToArray(simulation.biomass);
      const transpirationData: string[] = TypeConverter.convertStringToArray(simulation.transpiration);
      const nUptakeData: string[] = TypeConverter.convertStringToArray(simulation.nitrogenUptake);

      simulationModel.coverCrop = TypeConverter.convertCoverCropStringToNumericRepresentation(simulation.coverCrop);
      simulationModel.metFile = TypeConverter.convertMetFileStringToNumericRepresentation(simulation.metFile);
      simulationModel.soilProfile = TypeConverter.convertSoilProfileStringToNumericRepresentation(simulation.soilProfile);
      simulationModel.startMonth = parseInt(simulation.startMonth, 10);
      simulationModel.startDay = parseInt(simulation.startDay, 10);
      simulationModel.startWeek = DaysToWeeksConverter.convertToWeeks(simulationModel.startDay);
      simulationModel.terminationMonth = parseInt(simulation.terminationMonth, 10);
      simulationModel.terminationDay = parseInt(simulation.terminationDay, 10);
      simulationModel.terminationWeek = DaysToWeeksConverter.convertToWeeks(simulationModel.terminationDay);
      simulationModel.dates = TypeConverter.convertStringToArray(simulation.date);

      const biomassYearlyArr = [];
      biomassYearlyArr.push(BarGraphComponent.yearToModelResultMapping(
        biomassData, simulationModel.startDay, simulationModel.startMonth,
        simulationModel.terminationDay, simulationModel.terminationMonth,
        simulationModel.dates, ColumnEnums.RYE_BIOMASS));

      const transpirationYearlyArr = [];
      transpirationYearlyArr.push(BarGraphComponent.yearToModelResultMapping(
        transpirationData, simulationModel.startDay, simulationModel.startMonth,
        simulationModel.terminationDay, simulationModel.terminationMonth,
        simulationModel.dates, ColumnEnums.TRANSPIRATION));

      const nUptakeYearlyArr = [];
      nUptakeYearlyArr.push(BarGraphComponent.yearToModelResultMapping(
        nUptakeData, simulationModel.startDay, simulationModel.startMonth,
        simulationModel.terminationDay, simulationModel.terminationMonth,
        simulationModel.dates, ColumnEnums.NITROGEN_UPTAKE));

      const biomassYearlyMax: YearlyValue[] = BarGraphComponent.getAdjustedYearly_Max(Array.from(biomassYearlyArr));
      simulationModel.avgBiomass = d3.mean(biomassYearlyMax.map(d => d.value));

      const transpirationYearlySum: YearlyValue[] = BarGraphComponent.getAdjustedYearly_Sum(Array.from(transpirationYearlyArr));
      simulationModel.avgTranspiration = d3.mean(transpirationYearlySum.map(d => d.value));

      const nUptakeYearlyMax: YearlyValue[] = BarGraphComponent.getAdjustedYearly_Max(Array.from(nUptakeYearlyArr));
      simulationModel.avgNitrogenUptake = d3.mean(nUptakeYearlyMax.map(d => d.value));

      this.simulationModels.push(simulationModel);
    });

    this.loadFeaturesInSVG(this.simulationModels, '#simulationReport');
  }

  /**
   * Calls different functions to load the respective models.
   * Generates a report with parallel coordinate graph for all the simulations and corresponding results returned by the service endpoint.
   */
  private generateSimulationResultReport(simulationResultModels: SimulationResultModel[]) {
    this.loadSimulationModels(simulationResultModels);
  }

  /**
   * Sends http GET request to Apsim Micro Service for fetching all the simulation Results from database
   * Calls a method  for generating report for all the simulations and corresponding results returned by the service endpoint.
   */
  private fetchSimulationResultModelsAndGenerateReport() {
    this.http.get(this.URL + this.apiServiceEndpoint, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .append('Access-Control-Allow-Methods', 'POST')
        .append('Access-Control-Allow-Origin', '*')
        .set('Authorization', 'Basic ' + btoa('unl_admin:Covercrop123$'))
        // tslint:disable-next-line:max-line-length
        .append('Access-Control-Allow-Headers', 'Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method'),
      responseType: 'json'
    })
      .subscribe(simulationResults => {
          this.simulationResultModels = JSON.parse(JSON.stringify(simulationResults));
          this.loading = false;
          this.generateSimulationResultReport(this.simulationResultModels);
        },
        (error: HttpErrorResponse) => {
          if (error.status === StatusCodes.NOT_FOUND) {
            const errorMessage = error.error[0].error;
            this.notifyService.showWarning(errorMessage, 'Apsim Micro-service');
          } else {
            this.notifyService.showError('Failed to fetch Simulation Results !!', 'Apsim Micro-service');
          }
        }
      );
  }

  private loadFeaturesInSVG(models: any, report: string) {

    const self = this;

    /* Set the dimensions for the graph. */
    const margin = {top: 10, right: 30, bottom: 100, left: 60};
    const width = 1300 - margin.left - margin.right;
    const height = 670 - margin.top - margin.bottom;

    const x =  d3.scalePoint()
      .range([0, width])
      .padding(1);
    const y = {};
    // @ts-ignore
    const axis = d3.axisLeft();
    const line = d3.line().curve(d3.curveMonotoneX);
    let foreground;
    let dimensions = [];

    // tslint:disable-next-line:no-shadowed-variable
    const maxBiomass = parseInt(d3.max(models.map(x => x.avgBiomass)), 10);
    // Load biomass Ticks with intervals of 2000
    for (let i = 0; i < maxBiomass + 2000; i = i + 2000) {
      this.biomassTicks.push(i);
    }

    // tslint:disable-next-line:no-shadowed-variable
    const maxTranspiration = parseInt(d3.max(models.map(x => x.avgTranspiration)), 10);
    // Load Transpiration Ticks with intervals of 2
    for (let i = 0; i < maxTranspiration + 2; i = i + 2) {
      this.transpirationTicks.push(i);
    }

    // tslint:disable-next-line:no-shadowed-variable
    const maxNUptake = parseInt(d3.max(models.map(x => x.avgNitrogenUptake)), 10);
    // Load NUptake Ticks with intervals of 20
    for (let i = 0; i < maxNUptake + 20; i = i + 20) {
      this.nUptakeTicks.push(i);
    }

    // @ts-ignore
    const colorScale = ['#bd3726', '#8b89c7', '#2132b0', '#3b8c35', '#ffff00'];

    const svg = d3.select(report)
      .attr('viewBox', '25 -25 1200 690')
      .classed('svg-content-responsive', true)
      .append('g')
      .attr('transform', 'translate(' + margin.left + ',' + 25 + ')');

    // Biomass
    x.domain(dimensions = d3.keys(models[0]).
    filter(d => {
      if (d === 'metFile') {
        return y[d] = d3.scaleLinear()
          .domain(d3.extent(this.metFiles.map(c => TypeConverter.convertMetFileStringToNumericRepresentation(c)), p => {
            return +p;
          }))
          .range([height, 0]);
      } else if (d === 'soilProfile') {
        return y[d] = d3.scaleLinear()
          .domain(d3.extent(this.soilProfiles.map(c => TypeConverter.convertSoilProfileStringToNumericRepresentation(c)), p => {
            return +p;
          }))
          .range([height, 0]);
      } else if (d === 'startWeek' || d === 'terminationWeek') {
        return y[d] = d3.scaleLinear()
          .domain(d3.extent(this.weeks.map(c => TypeConverter.convertWeeksStringToNumericRepresentation(c)), p => {
            return +p;
          }))
          .range([height, 0]);
      } else if (d === 'startMonth') {
        return y[d] = d3.scaleLinear()
          .domain(d3.extent(this.startMonths.map(c => TypeConverter.convertStringStartMonthsToNumericRepresentation(c)), p => {
            return +p;
          }))
          .range([height, 0]);
      } else if (d === 'terminationMonth') {
        return y[d] = d3.scaleLinear()
          .domain(d3.extent(this.terminationMonths.map(c => TypeConverter.convertStringTerminationMonthsToNumericRepresentation(c)), p => {
            return +p;
          }))
          .range([height, 0]);
      } else if (d === 'coverCrop') {
        return y[d] = d3.scaleLinear()
          .domain(d3.extent(this.coverCrops.map(c => TypeConverter.convertCoverCropStringToNumericRepresentation(c)), p => {
            return +p;
          }))
          .range([height, 0]);
      } else if (d === 'avgBiomass') {
        return  y[d] = d3.scaleLinear()
          .domain(d3.extent(this.biomassTicks, p => {
            return +p;
          }))
          .range([height, 0]);
      } else if (d === 'avgTranspiration') {
        return  y[d] = d3.scaleLinear()
          .domain(d3.extent(this.transpirationTicks, p => {
            return +p;
          }))
          .range([height, 0]);
      } else if (d === 'avgNitrogenUptake') {
        return  y[d] = d3.scaleLinear()
          .domain(d3.extent(this.nUptakeTicks, p => {
            return +p;
          }))
          .range([height, 0]);
      } else {
        return false;
      }
    }));

    // Add grey background lines for context
    svg.append('g')
      .attr('class', 'background')
      .selectAll('path')
      .data(models)
      .enter().append('path')
      .attr('d', path);

    // Add blue foreground lines for focus.
    foreground = svg.append('g')
      .attr('class', 'foreground')
      .selectAll('path')
      .data(models)
      .enter().append('path')
      // tslint:disable-next-line:only-arrow-functions
      .each(function(d: SimulationModel) {
        d3.select(this)
          .attr('class', 'lines')
          .style('stroke', colorScale[Math.floor(d.avgBiomass / 2000)]);
      })
      .attr('d', path);

    // Add a group element for each dimension.
    const g = svg.selectAll('.dimension')
      .data(dimensions)
      .enter().append('g')
      .attr('class', 'dimension')
      .attr('transform', d => 'translate(' + x(d) + ')');


    // Add an axis and title.
    g.append('g')
      .attr('class', 'axis')
      .each(function(d) {
        if (d === 'startWeek' || d === 'terminationWeek') {
          d3.select(this)
            .call(axis
              .scale(y[d])
              .tickValues(self.weeks.map(c => TypeConverter.convertWeeksStringToNumericRepresentation(c)))
              .tickFormat((ticks, i) => self.weeks[i])
            );
        } else if (d === 'startMonth') {
          d3.select(this)
            .call(axis
              .scale(y[d])
              .tickValues(self.startMonths.map(c => TypeConverter.convertStringStartMonthsToNumericRepresentation(c)))
              .tickFormat((ticks, i) => self.startMonths[i])
            );
        } else if (d === 'terminationMonth') {
          d3.select(this)
            .call(axis
              .scale(y[d])
              .tickValues(self.terminationMonths.map(c => TypeConverter.convertStringTerminationMonthsToNumericRepresentation(c)))
              .tickFormat((ticks, i) => self.terminationMonths[i])
            );
        } else if (d === 'avgBiomass') {
          d3.select(this)
            .call(axis
              .scale(y[d])
              .tickValues(self.biomassTicks)
              .tickFormat((ticks, i) => self.biomassTicks[i])
            );
        } else if (d === 'avgTranspiration') {
          d3.select(this)
            .call(axis
              .scale(y[d])
              .tickValues(self.transpirationTicks)
              .tickFormat((ticks, i) => self.transpirationTicks[i])
            );
        } else if (d === 'avgNitrogenUptake') {
          d3.select(this)
            .call(axis
              .scale(y[d])
              .tickValues(self.nUptakeTicks)
              .tickFormat((ticks, i) => self.nUptakeTicks[i])
            );
        } else if (d === 'metFile') {
          d3.select(this)
            .call(axis
              .scale(y[d])
              .tickValues(self.metFiles.map(c => TypeConverter.convertMetFileStringToNumericRepresentation(c)))
              .tickFormat((ticks, i) => self.metFiles[i])
            );
        } else if (d === 'soilProfile') {
          d3.select(this)
            .call(axis
              .scale(y[d])
              .tickValues(self.soilProfiles.map(c => TypeConverter.convertSoilProfileStringToNumericRepresentation(c)))
              .tickFormat((ticks, i) => self.soilProfiles[i])
            );
        } else if (d === 'coverCrop') {
          d3.select(this)
            .call(axis
              .scale(y[d])
              .tickValues(self.coverCrops.map(c => TypeConverter.convertCoverCropStringToNumericRepresentation(c)))
              .tickFormat((ticks, i) => self.coverCrops[i])
            );
        }
      })
      .append('text')
      .style('text-anchor', 'middle')
      .style('font-size', 'small')
      .attr('y', -9)
      .attr('transform', 'rotate(-30,' + -20 + ',' + 10 + ')')
      // tslint:disable-next-line:only-arrow-functions
      .text(function(d) { return d; });

    // Add and store a brush for each axis.
    g.append('g')
      .attr('class', 'brush')
      .each(function(d) {
        d3.select(this).call(y[d].brush = d3.brushY()
          .extent([[-10, 0], [10, height]])
          .on('brush', brush)
          .on('end', brush)
        );
      })
      .selectAll('rect')
      .attr('x', -8)
      .attr('width', 16);

    // Returns the path for a given data point.
    function path(d) {
      return line(dimensions.map(p => [x(p), y[p](d[p])]));
    }

    // Handles a brush event, toggling the display of foreground lines.
    function brush() {
      const actives = [];
      svg.selectAll('.brush')
        .filter(function(d) {
          // @ts-ignore
          y[d].brushSelectionValue = d3.brushSelection(this);
          // @ts-ignore
          return d3.brushSelection(this);
        })
        .each(function(d) {
          // Get extents of brush along each active selection axis (the Y axes)
          actives.push({
            dimension: d,
            // @ts-ignore
            extent: d3.brushSelection(this).map(y[d].invert)
          });
        });

      // Update foreground to only display selected values
      foreground.style('display', d =>
        actives.every(active => active.extent[1] <= d[active.dimension] && d[active.dimension] <= active.extent[0]) ? null : 'none'
      );
    }

  }
}
