export enum ColumnEnums {
  DATE = 'Date',
  RYE_BIOMASS = 'Rye Biomass',
  TRANSPIRATION = 'Transpiration',
  SOIL_EVAPORATION = 'Soil Evaporation',
  SOIL_WATER = 'Soil Water',
  NITROGEN_UPTAKE = 'Nitrogen Uptake',
  CD = 'Cold & Dry',
  CW = 'Cold & Wet',
  WD = 'Warm & Dry',
  WW = 'Warm & Wet',
}
