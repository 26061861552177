import {Component, OnInit} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {ApsimService} from './apsim.service';
import {ApsimModel} from './apsim.model';
import {ResultModel} from '../bar-graph/result.model';
import {SelectorModel} from '../selector/selector.model';
import {SoilProfileEnums} from '../bar-graph/soil-profile.enums';
import {StatusCodes} from 'http-status-codes';
import {NotificationService} from '../../../notifications/notification.service';


@Component({
  selector: 'app-apsim',
  templateUrl: './apsim.component.html',
  styleUrls: ['./apsim.component.css']
})
export class ApsimComponent implements OnInit {

  private apsimModel: ApsimModel;

  private apsimModels: ApsimModel[];
  public resultModels: ResultModel[];

  /** URL to the Apsim backend Services */
  private URL = 'https://covercrops.unl.edu/api/';
  private apiServiceEndpoint = 'apsim/simulation/';
  public loading: boolean;
  public modelInComplete = true;
  public compare: boolean;

  constructor(private http: HttpClient, private apsimService: ApsimService, private notifyService: NotificationService) {}

  ngOnInit() {
    this.loading = false;
    this.compare = false;
    this.apsimModels = [];
    this.resultModels = [];
    this.apsimModel = {
      metFile: '',
      coverCrop: '',
      soilProfile: '',
      startDay: '',
      startMonth: '',
      terminationDay: '',
      terminationMonth: ''
    };

    this.apsimService.selectedMetFile.subscribe(
      (metFile: string) => {
        this.apsimModel.metFile = metFile;
        this.modelInComplete = Object.values(this.apsimModel).some(x => x === '');
      });

    this.apsimService.selectedOptions.subscribe(
      (selectedOption: SelectorModel) => {
        this.apsimModel.coverCrop = selectedOption.coverCrop;
        this.apsimModel.startDay = selectedOption.startDay;
        this.apsimModel.startMonth = selectedOption.startMonth;
        this.apsimModel.terminationDay = selectedOption.terminationDay;
        this.apsimModel.terminationMonth = selectedOption.terminationMonth;

        /* Change the value of selected coverCrop in apsimModel according to the apsimMicroservice needs. */
        switch (selectedOption.soilProfile) {
          case SoilProfileEnums.Crete_Silt_Loam_No3824:
            this.apsimModel.soilProfile = 'Crete Silt Loam No3824';
            break;
          case SoilProfileEnums.Sandy_loam_No200:
            this.apsimModel.soilProfile = 'Sandy loam No200';
            break;
          case SoilProfileEnums.Wabash_Silty_Clay_Loam:
            this.apsimModel.soilProfile = 'Wabash Silty Clay Loam';
            break;
          case SoilProfileEnums.Thurman_Crofton_Sand:
            this.apsimModel.soilProfile = 'Thurman Crofton Sand';
            break;
          default:
            break;
        }

        this.modelInComplete = Object.values(this.apsimModel).some(x => x === '');
      });

  }

  private compareFeatureToggle() {
    this.apsimService.compare.emit(this.compare);

    if (!this.compare) {
      const firstApsimModel = this.apsimModels[0];
      const firstResultModel = this.resultModels[0];
      this.apsimModels = [firstApsimModel];
      this.resultModels = [firstResultModel];

      this.apsimService.apsimModels.emit(this.apsimModels);
      this.apsimService.resultModels.emit(this.resultModels);
    }
  }

  /**
   * Sends http POST request to Apsim Micro Service for processing and retrieving the ResultModel Object
   */
  private getResultModel(apsimModel: ApsimModel) {

    if (this.apsimModels.length < 3) {
      this.changeLoadingState(true);

      const jsonRequestObject = JSON.parse(JSON.stringify(apsimModel));
      this.http.post(this.URL + this.apiServiceEndpoint, jsonRequestObject, {
        headers: new HttpHeaders()
          .set('Content_Type', 'application/json')
          .append('Access-Control-Allow-Methods', 'POST')
          .append('Access-Control-Allow-Origin', '*')
          .set('Authorization', 'Basic ' + btoa('farmer:farmer'))
          // tslint:disable-next-line:max-line-length
          .append('Access-Control-Allow-Headers', 'Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method'),
        responseType: 'json'
      })
        .subscribe(
          resultModel => {
            this.changeLoadingState(false);
            if (!this.compare) {
              this.apsimModels = [];
              this.resultModels = [];
            }
            this.apsimModels.push({
              coverCrop: apsimModel.coverCrop,
              metFile: apsimModel.metFile,
              soilProfile: apsimModel.soilProfile,
              startDay: apsimModel.startDay,
              startMonth: apsimModel.startMonth,
              terminationDay: apsimModel.terminationDay,
              terminationMonth: apsimModel.terminationMonth,
            });
            this.resultModels.push(resultModel as ResultModel);
            this.apsimService.apsimModels.emit(this.apsimModels);
            this.apsimService.resultModels.emit(this.resultModels);
          },
          (error: HttpErrorResponse) => {
            if (error.status === StatusCodes.SERVICE_UNAVAILABLE) {
              const errorMessage = error.error[0].error;
              this.notifyService.showWarning( errorMessage, 'Apsim Micro-service');
            } else {
              this.notifyService.showError('Simulation failed !!', 'Apsim Micro-service');
            }
            this.changeLoadingState(false);
          }
        );
    } else {
      this.notifyService.showWarning('Maximum allowed simulation comparison exceeded !', 'BioMass Estimator');
    }
  }

  private changeLoadingState(state: boolean) {
    this.loading = state;
    this.apsimService.loading.emit(this.loading);
  }

  /**
   * Method that uses the inbuilt prototype .out file to generate static graphs.
   */
  /*private getResultModels(apsimModel) {
    this.loading = true;  /!* Set to true for spin wheel *!/
    this.apsimService.loading.emit(this.loading);
    const coverCropFilePath = '../assets/data/out-file-template/90 day CRM.out'; /!* Hard coding the path of .out file *!/

    /!* start processing coverCrop out file *!/
    this.http.get(coverCropFilePath, {responseType: 'text'})
      .subscribe(
        coverData => {
          this.parseRawText(coverData).then((resultModel: ResultModel) => {
            this.loading = false;
            this.apsimService.loading.emit(this.loading);
            this.apsimService.apsimModel.emit(apsimModel);
            this.apsimService.resultModel.emit(resultModel);
          });
        },
        () => {
          this.loading = false;
        });
  }*/


  /**
   * Parse the text data
   * Return a promise for a processed data
   */
  /*private parseRawText(data: string) {
    return new Promise(resolve => {
      const indexToColumnMap = {} as { index: number, column: string };
      const resultModel = {} as ResultModel;
      const date: string[] = [];
      const biomass: string[] = [];
      const transpiration: string[] = [];
      const soilEvaporation: string[] = [];
      const soilWater: string[] = [];
      const nUptake: string[] = [];
      const lines = data.split('\n');
      lines.pop();
      const columns = lines[2].trim().split(/\s+/);
      columns.forEach((col, index) => {
        switch (col) {
          case 'Date':
            col = ColumnEnums.DATE;
            break;
          case 'paddock.wheat.biomass':
            col = ColumnEnums.RYE_BIOMASS;
            break;
          case 'paddock.wheat.n_uptake':
            col = ColumnEnums.NITROGEN_UPTAKE;
            break;
          case 'paddock.wheat.ep':
            col = ColumnEnums.TRANSPIRATION;
            break;
          case 'es':
            col = ColumnEnums.SOIL_EVAPORATION;
            break;
          case 'sw()':
            col = ColumnEnums.SOIL_WATER;
            break;
        }
        indexToColumnMap[index] = col;
      });

      /!* Returns array of lines starting at line 4 *!/
      lines.slice(4).forEach(line => {
        const colValues = line.trim().split(/\s+/);  /!* Split the line by spaces, and store inside colValues array variable *!/
        colValues.forEach((value, index) => {
          if (indexToColumnMap[index] === ColumnEnums.DATE) {
            date.push(value);
          } else if (indexToColumnMap[index] === ColumnEnums.RYE_BIOMASS) {
            biomass.push(String(UnitConversionsEnums.KG_PER_HECTARE_TO_LBS_PER_ACRE * parseFloat(value)));
          } else if (indexToColumnMap[index] === ColumnEnums.NITROGEN_UPTAKE) {
            nUptake.push(String(UnitConversionsEnums.GM_PER_METER_SQUARED_TO_LBS_PER_ACRE * parseFloat(value)));
          } else if (indexToColumnMap[index] === ColumnEnums.TRANSPIRATION) {
            transpiration.push(String(UnitConversionsEnums.MM_TO_INCHES * parseFloat(value)));
          } else if (indexToColumnMap[index] === ColumnEnums.SOIL_EVAPORATION) {
            soilEvaporation.push(value);
          } else if (indexToColumnMap[index] === ColumnEnums.SOIL_WATER) {
            soilWater.push(value);
          }
        });
      });

      resultModel.date = date.join(';');
      resultModel.biomass = biomass.join(';');
      resultModel.transpiration = transpiration.join(';');
      resultModel.soilEvaporation = soilEvaporation.join(';');
      resultModel.soilWater = soilWater.join(';');
      resultModel.nitrogenUptake = nUptake.join(';');

      resolve(resultModel);
    });
  }*/

}
